<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">直播列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlExamination"
          style="align-items: flex-start"
        >
          <div
            class="searchbox"
            style="display: flex; align-items: center;margin-bottom: 15px;"
          >
            <div title="班级编号" class="searchboxItem ci-full">
              <span class="itemLabel">班级编号:</span>
              <el-input
                v-model="searchData.projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编号"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="searchData.liveProjectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="开班单位" class="searchboxItem ci-full">
              <span class="itemLabel">开班单位:</span>
              <el-input
                v-model="searchData.compName"
                placeholder="请输入开班单位"
                size="small"
                clearable
              />
            </div>
          </div>
          <div
            class="searchbox"
            style="display: flex; align-items: center;"
          >
            <div title="行政区划" class="searchboxItem ci-full">
                <span class="itemLabel">行政区划:</span>
                <el-cascader
                    v-model="searchData.areaId"
                    :options="areatreeList"
                    :props="propsarea"
                    clearable
                    filterable
                    size="small"
                ></el-cascader>
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select 
                clearable
                size="small"
                v-model="searchData.projectState" 
                placeholder="请选择">
                <el-option
                    v-for="item in projectStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
               <el-date-picker
                  size="small"
                  v-model="searchData.creationTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
                <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                    width="60"
                    fixed
                    :index="indexMethod"
                />
                <el-table-column
                    label="班级编号"
                    align="left"
                    show-overflow-tooltip
                    prop="projectCode"
                    min-width="200"
                    fixed
                />
                <el-table-column
                    label="班级名称"
                    align="left"
                    show-overflow-tooltip
                    prop="liveProjectName"
                    min-width="150"
                    fixed
                />
                <el-table-column
                    label="期次"
                    align="left"
                    show-overflow-tooltip
                    prop="periodName"
                    min-width="120"
                />
                <el-table-column
                    label="行政区划"
                    align="left"
                    show-overflow-tooltip
                    prop="areaName"
                    min-width="120"
                />
                <el-table-column
                    label="培训类型"
                    align="left"
                    show-overflow-tooltip
                    prop="trainTypeNamePath"
                    min-width="120"
                />
                <el-table-column
                    label="开办单位"
                    align="left"
                    show-overflow-tooltip
                    prop="compName"
                    min-width="120"
                />
                <el-table-column
                    label="负责人"
                    align="left"
                    show-overflow-tooltip
                    prop="projectUser"
                    min-width="120"
                />
                <el-table-column
                    label="负责人电话"
                    align="left"
                    show-overflow-tooltip
                    prop="projectUserPhone"
                    min-width="120"
                />
                <el-table-column
                    label="开始时间"
                    align="left"
                    show-overflow-tooltip
                    prop="startDate"
                    min-width="120"
                />
                <el-table-column
                    label="结束时间"
                    align="left"
                    show-overflow-tooltip
                    prop="endDate"
                    min-width="120"
                />
                <el-table-column
                    label="入班人数"
                    align="left"
                    show-overflow-tooltip
                    prop="projectPeople"
                    min-width="120"
                />
                <el-table-column
                    label="完成人数"
                    align="left"
                    show-overflow-tooltip
                    prop="finishedPeople"
                    min-width="120"
                />
                <el-table-column
                    label="培训进度"
                    align="left"
                    show-overflow-tooltip
                    prop="trainSchedule"
                    min-width="120"
                />
                <el-table-column
                    label="班级状态"
                    align="left"
                    show-overflow-tooltip
                    prop="projectState"
                    min-width="120"
                >
                <template slot-scope="{ row }">
                    {{$setDictionary('PROJECTSTATE',row.projectState)}}
                </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    align="left"
                    show-overflow-tooltip
                    prop="createTime"
                    min-width="150"
                />
                <el-table-column label="操作" align="center" fixed="right" width="250">
                    <div slot-scope="scope" class="flexcc">
                    <el-button
                        type="text"
                        style="padding: 0px 5px"
                        size="mini"
                        @click="handledetail(scope.row,'first')"
                        >详情</el-button
                    >
                    <el-button
                        type="text"
                        style="padding: 0px 5px"
                        size="mini"
                        @click="handledetail(scope.row,'second')"
                        >开课记录</el-button
                    >
                    <el-button
                        type="text"
                        style="padding: 0px 5px"
                        size="mini"
                        @click="handledetail(scope.row,'three')"
                        >学员信息</el-button
                    >
                    <el-button
                        type="text"
                        style="padding: 0px 5px"
                        size="mini"
                        @click="handledetail(scope.row,'four')"
                        >班级参数</el-button
                    >
                    </div>
                </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";

export default {
  name: "liveTheClassList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
        searchData:{
            projectCode:'',//班级编号
            liveProjectName:'',//班级名称
            areaId:'',//行政区划
            projectState:'',//班级状态
            creationTime:[],//创建时间
            compName:'',//开班单位
        },
        // 行政区划
        areatreeList: [],
        propsarea: {
            value: "value",
            label: "label",
            emitPath: false,
            checkStrictly: true,
        },
        // 班级状态list
        projectStateList:[]
    };
  },
  created() {
    this.getareatree();
    this.getprojectStatetypeList();
  },
   computed: {
    
  },
  methods: {
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getprojectStatetypeList() {
      const list = this.$setDictionary("PROJECTSTATE", "list");
      const data = [];
      for (const key in list) {
        data.push({
          value: key,
          label: list[key],
        });
      }
      this.projectStateList = data;
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if(this.searchData.creationTime&&this.searchData.creationTime.length == 2){
        params.createStartDate = this.searchData.creationTime[0]
        params.createEndDate = this.searchData.creationTime[1]
      }
      params = {
        ...params,
        ...this.searchData,
      };
      this.doFetch({
        url: "/liveProject/live-project/pageList",
        params,
        pageNum,
      },true,3);
    },
    handledetail(row,active){
      sessionStorage.setItem("liveProjectId", row.liveProjectId);
      sessionStorage.setItem("liveProjectName", row.liveProjectName);
      sessionStorage.setItem("projectState", row.projectState);
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveTabs",
        query: {
          active
        },
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.operationControlExamination {
  > div {
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .searchboxs {
    justify-content: flex-start;
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
